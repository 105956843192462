@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root{
  --primary-background:#171933;
  --secondary-background:#191C3B;
  --main:#0076E8;
  --primary-text:#8CBEDE;
  --primary-text-back:#0075BD;
  --secondary-text:#ffffff;
  --tertiary-text: #BCC4EB;
  --box-border:#191C39;
  --box-background:#1E203A;
  --secondary-box-border:rgba(73, 81, 120, 0.50);
  --secondary-box-background:rgba(67, 80, 128, 0.20);
  --extra-box-background:rgba(67, 80, 128, 0.20);
  --line:rgba(228, 228, 231, 0.5); 
  --footer-responsive:#192657; 
  --about-container:#121B40;
  --about-secondary-container:#222436;
}

body{
  background-color: var(--primary-background);
}

/* navbar starts here  */
nav{
  display: flex;
  justify-content: space-between;
  padding: 0.95% 9.5%;
  background-color: var(--secondary-background);
  align-items: center;
  transition: all 0.4s ease-in-out;
  z-index: 10;
}
nav.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.nav-logo img{
  height: 70px;
}

.pages a{
  text-decoration: none;
  color: var(--secondary-text);
  font-size: 20px;
  padding: 10px;
  transition: 0.4s all ease-in-out;
}
.pages a:hover{
  font-weight: bold;
}
.active{
  font-weight: bold;
}
.checkbtn-label{
  font-size: 28px;
  color: var(--secondary-text);
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: none;
  }
  
  
  #check{
  display: none;
  }

  @media screen  and (max-width:1050px) {
    .pages a{
      font-size: 18px;
    }
  }
  @media screen  and (max-width:950px) {
    .pages a{
      font-size: 16px;
      padding: 8px;
    }
    .nav-logo img{
      height: 60px;
    }
  }

  @media (max-width:800px){
    .checkbtn-label{
      display: block;
    }
    .pages{
      position: fixed;
      width: 100%;
      height: 100vh;
      background: var(--footer-responsive);
      top: 9%;
      left: -100%;
      text-align: center;
      transition: all .5s;
      z-index: 9;
    }
    .pages.open {
      left: 0;
    }
  
    .pages a {
      display: block;
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: white;
    }
  }

  /* navbar ends here  */

  /* footer starts here  */
  footer{
    background-color: var(--secondary-background);
    padding: 100px 12%;
  }
  
  .footer-up{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .site-map, .socials, .office{
    display: flex;
    flex-direction: column;
  }
  .site-map h4, .socials h4{
    font-family: 'Cinzel', serif;
    font-size: 15px;
    font-weight: 200;
    opacity: 0.9;
    margin-bottom: 10px;
    color: var(--secondary-text);
  }
  .site-map a, .socials a{
    text-decoration: none;
    color: var(--secondary-text);
    font-size: 13px;
    opacity: 0.75;
    font-weight: 200;
    margin: 5px 0;
    transition: 0.4s all ease-in-out;
  }
  .site-map a:hover, .socials a:hover {
    transform: scale(1.1);
    opacity: 1;    
  }
  .office{
    width: 25%;
  }
  .office h4{
    font-family: 'Cinzel', serif;
    font-size: 15px;
    font-weight: 200;
    opacity: 0.9;
    color: var(--secondary-text);
  }
  
  .office p{
    color: var(--secondary-text);
    font-size: 13px;
    opacity: 0.75;
    font-weight: 200;
    margin: 5px 0;
  }
  #news-letter{
    margin-top: 70px;
  }
  
  .office form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--line);
  }
  .office form input{
    background: none;
    margin-top: 10px;
    border: none;
    color: var(--secondary-text);
    width: 100%;
    padding:10px 20px 10px 0px;
  }
  
  .office form button{
    background: var(--main);
    /* width: 150px; */
    /* display: inline; */

    padding: 14px;
    border-radius: 8px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    color: var(--secondary-text);
    transition: all 0.4s ease-in-out ;
  }
  .office form button:hover{
    background: var(--secondary-text);
    color: var(--main);
    font-weight: bold;
  }
  .office form button i{
    font-size: 24px;
    font-weight: 100;
  }
  
  .footer-buttom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }
  .footer-buttom-link{
    padding-bottom: 10px;
    border-bottom: 2px solid var(--line);
    transition: 0.4s all ease-in-out;
  }  
  .footer-buttom-link a{
    text-decoration: none;
    padding-bottom: 10px;
    color: var(--secondary-text);
    opacity: 0.75;
    font-size: 16px;
    
  }
  .footer-buttom-link:hover{
    transform: scale(1.1);
  }

  .footer-buttom p{
    opacity: 0.75;
    color: var(--secondary-text);
    font-size: 11px;
  }

  @media screen  and (max-width:950px) {
    .footer-up{
      flex-wrap: wrap;
    }
    .footer-logo{
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    .office{
      width: 35%;
    }
  }
  @media screen  and (max-width:850px) {
   
    .footer-buttom-link{
      padding-bottom: 7px;
    }
    .footer-buttom-link a{
      font-size: 12px;
      
    }
    .footer-buttom p{
      font-size: 10px;
    }
  }
  @media screen  and (max-width:750px) {
    .footer-buttom p{
      width: 30%;
    }
    .office{
      width: 40%;
    }
  }
  @media screen  and (max-width:700px) {
    .footer-buttom p{
      width: 30%;
    }
  }
  @media screen  and (max-width:600px) {
    footer{
      background-color: var(--footer-responsive);
      padding: 30px 12%;
    }
    
    .footer-up{
      flex-direction: column;
    }
    .site-map, .socials{
      padding-bottom: 15px;
      margin-bottom: 15px;
      width: 100%;
      border-bottom: 1px solid var(--line);
    }
     .footer-buttom{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .form{
      width: 100%;
      justify-content: space-between;
    }
    .footer-buttom-link{
      /* margin-bottom: 20px; */
      border-bottom: none;
    }
    .footer-buttom-link a{
      font-size: 15x;
    }
    .footer-buttom p{
      position: relative;
      margin-top: 50px;
      font-size: 9px;
      width: 100%;
    }
    
    .office{
      width: 100%;
      border-bottom: 1px solid var(--line);
    }
  }

  /* footer ends here  */

  /* home starts here  */

  /* home front with cube starts here  */
  .home{
    display: flex;
    margin-top: 50px;
    padding-left: 9.5%;
    flex-wrap: nowrap;
  }
  .home-content{
    width: 95%;
    z-index: 2;
  }
  .home-content h2{
    font-family: "Ubuntu", sans-serif;
    color: var(--secondary-text);
    font-size: 75px;
    font-weight: 400;
    margin-top: 70px;
  }
  .home-content span{
    color: var(--main);
  }
  
  .home-content p{
    color: var(--secondary-text);
    margin: 30px 0;
    opacity: 0.55;
    font-size: 21px;
    width: 80%;
  }
  
  .home-content button{
    padding: 15px 25px;
    border: none;
    border-radius: 15px;
    background-color: var(--main);
    transition: all 0.4s ease-in-out;
  }
  .home-content button a{
    text-decoration: none;
    color: var(--secondary-text);
    font-size: 20px;
    transition: all 0.4s ease-in-out;
  }
  .home-content button:hover {
    background-color: var(--secondary-text);
    border: 1px solid var(--main);
    box-shadow: 0px 2px 4px rgba(3, 0, 59, 0.7);
  }
  .home-content button:hover a{
    font-weight: bold;
    color: var(--main);
  }

  .home-cubes img{
    width: 45vw;
    margin-right: 20px;
  }
  .home-cubes video{
    width: 45vw;
    margin-right: 20px;
  }

  @media screen  and (max-width:1400px) {
    .home-cubes img{
      width: 42vw;
    }
    .home-cubes video{
      width: 42vw;
    }
  }
  @media screen  and (max-width:1300px) {
    .home-content h2{
      font-size: 70px;
    }
    .home-content p{
      font-size: 20px;
    }
    .home-content button a{
      font-size: 19px;
    }
  }
  @media screen  and (max-width:1240px) {
    .home-content h2{
      font-size: 65px;
      margin-top: 60px;
    }
    .home-content p{
      font-size: 18px;
    }
    .home-content button a{
      font-size: 17px;
    }
  }
  @media screen  and (max-width:1140px) {
    .home-content h2{
      font-size: 60px;
      margin-top: 50px;
    }
    .home-content p{
      font-size: 16px;
    }
    .home-content button a{
      font-size: 15px;
    }
    .home-cubes img{
      width: 40vw;
      /* margin-top: 30px; */
    }
    .home-cubes video{
      width: 40vw;
      /* margin-top: 30px; */
    }
  }
  @media screen  and (max-width:1020px) {
    .home{
      flex-direction: column-reverse;
      padding: 40px 9.5%;
      margin-top: 0;
    }
    .home-cubes{
      display: flex;
      justify-content: center;
    }
    .home-cubes img{
      width: 500px;
    }
    .home-cubes video{
      width: 500px;
    }
    .home-content h2{
      font-size: 70px;
      font-weight: 400;
      margin-top: 20px;
    }
    .home-content p{
      font-size: 20px;
      width: 90%;
    }
    .home-content button a{
      font-size: 19px;
    }
  }
  @media screen  and (max-width:720px) {
    
    .home-cubes img{
      width: 450px;
    }
    .home-cubes video{
      width: 450px;
    }

    .home-content h2{
      font-size: 60px;
    }
    .home-content p{
      font-size: 18px;
    }
    .home-content button a{
      font-size: 17px;
    }
  }
  @media screen  and (max-width:620px) {
    
    .home-cubes img{
      width: 400px;
    }
    .home-cubes video{
      width: 400px;
    }
    .home-content h2{
      font-size: 50px;
      margin-top: 10px;
    }
    .home-content p{
      font-size: 16px;
    }
    .home-content button a{
      font-size: 15px;
    }
    .home-content button{
      padding: 10px 15px;
      border-radius: 10px;
    }
  }
  @media screen  and (max-width:520px) {
    
    .home-cubes img{
      width: 350px;
    }
    .home-cubes video{
      width: 350px;
    }
    .home-content h2{
      font-size: 40px;
      margin-top: 0px;
    }
    /* .home-content p{
      font-size: 14px;
    }
    .home-content button a{
      font-size: 13px;
    } */
  }
  @media screen  and (max-width:410px) {
    .home{
      margin-top: 0;
      padding-top: 20px;
    }
    .home-cubes img{
      width: 300px;
    }
    .home-cubes video{
      width: 300px;
    }
    .home-content h2{
      font-size: 35px;
      margin-top: 0px;
    }
    /* .home-content p{
      font-size: 14px;
      margin: 15px 0;
    }
    .home-content button a{
      font-size: 13px;
    } */
  }
  @media screen  and (max-width:360px) {
    .home-cubes img{
      width: 250px;
    }
    .home-cubes video{
      width: 250px;
    }
    .home-content h2{
      font-size: 30px;
      margin-top: 0px;
    }
    /* .home-content p{
      font-size: 13px;
      margin: 15px 0;
    }
    .home-content button a{
      font-size: 12px;
    } */
    .home-content button{
      padding: 8px 12px;
      border-radius: 8px;
    }
  }

/* home front ends here  */


/* network starts here  */

.network{
  padding: 0 9.5%;
}
.network-title{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.network-text{
  color: var(--secondary-text);
  font-size: 50px;
}
#network-text-back span{
  background: var(--primary-text-back);
  display: inline-block;
  padding: 0px 5px ;
  font-weight: 600;
}
/* #network-text-back span{
  background: var(--primary-background);
} */
#network-text-italic{
  font-style: italic;
}
.network-arrow i{
  background-color: var(--secondary-text);
  border-radius: 8px;
  color: var(--main);
  padding: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
#network-arrow-left{
  margin-right: 15px;
}
.network-arrow i:hover{
  background-color:var(--main) ;
  color:var(--secondary-text) ;
}

.network-container{
  display: flex;
  margin-top: 40px;
  overflow: hidden;
}
.network-card{
  flex: 0 0 210px;
  /* height: 210px; */
  margin-right: 20px;
  background: var(--secondary-text);
  border-radius: 12px;
  /* display: flex; */
  /* justify-content: center; */
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.6);
}
.network-card a{
  /* flex: 0 0 210px; */
  height: 210px;
  /* margin-right: 20px; */
  /* background: var(--secondary-text); */
  /* border-radius: 12px; */
  display: flex;
  justify-content: center;
}
.network-card img {
  max-width: 100%;
  max-height: 100%;
}

@media screen  and (max-width:1680px) {
  .network-card{
    flex: 0 0 200px;
  }
  .network-card a{
    height: 200px;
  }
}
@media screen  and (max-width:1520px) {
  .network-card{
    flex: 0 0 175px;
  }
  .network-card a{
    height: 175px;
  }
}
@media screen  and (max-width:1400px) {
  .network-card{
    flex: 0 0 200px;
  }
  .network-card a{
    height: 200px;
  }
}
@media screen  and (max-width:1300px) {
  .network-card{
    flex: 0 0 190px;
  }
  .network-card a{
    height: 190px;
  }
}
@media screen  and (max-width:1300px) {
  .network-card{
    flex: 0 0 180px;
  }
  .network-card a{
    height: 180px;
  }
}
@media screen  and (max-width:1180px) {
  .network{
    margin-top: 40px;
  }
  .network-card{
    flex: 0 0 170px;
  }
  .network-card a{
    height: 170px;
  }
}
@media screen  and (max-width:1120px) {
  .network-card{
    flex: 0 0 160px;
  }
  .network-card a{
    height: 160px;
  }
}
@media screen  and (max-width:1080px) {
  .network-card{
    flex: 0 0 150px;
  }
  .network-card a{
    height: 150px;
  }
}
@media screen  and (max-width:820px) {
  .network-card{
    flex: 0 0 140px;
  }
  .network-card a{
    height: 140px;
  }
  .network-text{
    font-size: 40px;
  }
  /* .network-arrow i{
    border-radius: 8px;
    padding: 12px;
  } */
  #network-arrow-left{
    margin-right: 10px;
  }
}
/* @media screen  and (max-width:760px) {
  .network-card{
    flex: 0 0 120px;
    margin-right: 10px;
  }
  .network-card a{
    height: 120px;
  }
} */
@media screen  and (max-width:620px) {
  /* .network-card{
    flex: 0 0 100px;
  }
  .network-card a{
    height: 100px;
  } */
  .network-text{
    font-size: 30px;
  }
  /* .network-arrow i{
    border-radius: 6px;
    padding: 10px;
  }
  #network-arrow-left{
    margin-right: 6px;
  } */
}
@media screen  and (max-width:450px) {
  .network-text{
    font-size: 25px;
  }
  .network-arrow i{
    /* border-radius: 5px; */
    padding: 14px;
    /* font-size: 1px; */
  }
  #network-arrow-left{
    margin-right: 5px;
  }
}
@media screen  and (max-width:375px) {
  .network-card{
    flex: 0 0 120px;
  }
  .network-card a{
    height: 120px;
  }
  .network-arrow i{
    padding: 12px;
  }
}

@media screen  and (max-width:350px) {
  .network-text{
    font-size: 20px;
  }
  .network-arrow i{
    /* border-radius: 5px; */
    padding: 10px;
    /* font-size: 10px; */
  }
  #network-arrow-left{
    margin-right: 4px;
  }
}

/* network ends here  */



/* objective start here  */

.objectives{
  width: 60%;
  margin: 50px auto;
}

.objective-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s all ease-in-out;
}
.objective-container:hover{
  transform: scale(1.05);
}

.objective-image img{
  width: 500px;
  height: 500px;
}

.objective-text{
  width: 35%;
}
.objective-text h2{
  color: var(--primary-text);
  font-size: 35px;
}
.objective-text p{
  color: var(--secondary-text);
  font-size: 18px;
  padding-top: 50px;
}

@media screen  and (max-width:1440px) {
  .objective-image img{
    width: 450px;
    height: 450px;
  }
}
@media screen  and (max-width:1300px) {
  .objectives{
    width: 65%;
    margin: 50px auto;
  }
}
@media screen  and (max-width:1200px) {
  .objectives{
    width: 70%;
    /* margin: 50px auto; */
  }
}
@media screen  and (max-width:1180px) {
  .objective-image img{
    width: 400px;
    height: 400px;
  }
}
@media screen  and (max-width:1000px) {
  .objectives{
    width: 75%;
  }
}
@media screen  and (max-width:900px) {
  .objective-container{
    flex-direction: column;
    align-items:flex-start ;
  }
  .objective-reversed{
    flex-direction: column-reverse;
  }
  .objective-text{
    width: 100%;
  }
  .objective-text p{
    padding: 30px 0;
  }
}
@media screen  and (max-width:800px) {
  .objective-image img{
    width: 350px;
    height: 350px;
  }
}
@media screen  and (max-width:600px) {
  .objective-image img{
    width: 300px;
    height: 300px;
  }
  .objective-text h2{
    font-size: 30px;
  }
  .objective-text p{
    font-size: 15px;
    padding-top: 20px;
  }
}
@media screen  and (max-width:500px) {
  .objective-image img{
    width: 250px;
    height: 250px;
  }
  .objective-text h2{
    font-size: 25px;
  }
  .objective-text p{
    /* font-size: 14px; */
    padding-top: 20px;
  }
}
@media screen  and (max-width:425px) {
  .objective-image img{
    width: 200px;
    height: 200px;
  }
}

/* objective ends here  */


/* home box starts here  */

.home-box{
  width: 88%;
  height: 550px;
  background-color: var(--box-background);
  border: 10px solid var(--box-border);
  margin: 50px auto;
  margin-top: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: clip;
}

.home-box-img1 img{
  height: 660px;
  width: auto;
  z-index: 2;
  position: relative;
  top: -42px;
  left: -110px;
}


.home-box-text{
  position: relative;
  /* margin-right: 60px; */
  width: 40%;
}
.home-box-text h2{
  color: var(--secondary-text);
  font-size: 40px;
}
.home-box-text p{
  color: var(--secondary-text);
  font-size: 16px;
  margin: 40px 0;
  font-weight: 300;
}

.home-box-text a{
  background-color: var(--main);
  color: var(--secondary-text);
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.4s ease-in-out;
}

.home-box-text a:hover {
  background-color: var(--secondary-text);
  border: 1px solid var(--main);
  box-shadow: 0px 2px 4px rgba(3, 0, 59, 0.7);
  color: var(--main);
}
@media screen  and (max-width:1400px) {
  .home-box-text{
    left: -100px;
    width: 100%;
  }
}
@media screen  and (max-width:1320px) {
  
.home-box{
  height: 500px;
}
.home-box-img1 img{
  height: 600px;
  top: -35px;
  left: -110px;
}
}
@media screen  and (max-width:1250px) {
  /* .home-box-text{

  } */
  .home-box-text h2{
    font-size: 35px;
  }
  
}
@media screen  and (max-width:1180px) {
  .home-box-text h2{
    font-size: 30px;
  }
  
.home-box{
  height: 450px;
}
.home-box-img1 img{
  height: 550px;
  top: -35px;
  left: -110px;
  overflow: hidden;
  /* width: 80%; */
}
}
@media screen  and (max-width:1060px) {
  .home-box-text h2{
    font-size: 30px;
  }
  
.home-box{
  height: 420px;
}
.home-box-img1 img{
  height: 500px;
  top: -30px;
  left: -110px;
}
}
@media screen  and (max-width:1000px) {
  .home-box{
    flex-direction: column;
  }
  /* .home-box-text h2{
    font-size: 30px;
  } */
  
.home-box{
  height: 750px;
}
.home-box-img1 img{
  height: 600px;
  margin: 0 auto;
  top: -100px;
  left: 0px;
}
.home-box-text{
  text-align: left;
  left: 0;
  width: 90%;
  top: -80px;
  
}
}
@media screen  and (max-width:850px) {
  .home-box-img1 img{
    height: 580px;
  }
  .home-box{
    overflow-x: clip;
  }
}
@media screen  and (max-width:600px) {
  .home-box{
    width: 100%;
  }
  .home-box-img1 img{
    left: -50px;
  }
}
@media screen  and (max-width:500px) {
  .home-box{
    height: 700px;
  }
  .home-box-img1 img{
    height: 500px;
    left: -50px;
  }
  .home-box-text h2{
    font-size: 30px;
  }
}
@media screen  and (max-width:420px) {
  .home-box{
    height: 620px;
  }
  .home-box-img1 img{
    height: 450px;
    left: -50px;
  }
  .home-box-text h2{
    font-size: 25px;
  }
  .home-box-text p{
    font-size: 15px;
  }
}
@media screen  and (max-width:350px) {
  .home-box-text h2{
    font-size: 22px;
  }
  .home-box-text p{
    font-size: 14px;
  }
  .home-box-text a{
    font-size: 13px;
    padding: 8px 15px;
  }
}

/* home box ends here  */


/* home help starts here  */
.home-help{
  margin: 90px 9.5% ;
  align-items: center;
}
.home-help h2{
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  color: var(--secondary-text);
}
.home-help span{
  background-color: var(--primary-text-back);
  padding: 0 5px;
}

.home-help-box{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.help-box{
  flex-basis: 33.3%;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: var(--secondary-text);
  transition: 0.4s all ease-in-out;
}

.help-box img{
  margin: 30px 0;
}

.help-box p{
  margin: 30px 0;
  color: var(--tertiary-text);
  font-size: 15px;
  transition: 0.4s all ease-in-out; 
}
.help-box:hover{
  background-color: var(--secondary-text);
  color: var(--main);
  box-shadow: 0px 2px 4px rgba(3, 0, 59, 0.7);
}
.help-box:hover p{
  opacity:  1 !important;
  color: var(--primary-background);
  opacity: 0.9;
}
.box1{
  border-right: 1px solid var(--line);
  border-bottom: 1px solid var(--line);
}
.box2{
  border-right: 1px solid var(--line);
  border-bottom: 1px solid var(--line);
}
.box3{
  border-bottom: 1px solid var(--line);
}
.box5{
  border-left: 1px solid var(--line);
  border-right: 1px solid var(--line);
}

@media screen  and (max-width:1240px) {
  .home-help h2{
    font-size: 45px;
  }
  .help-box h3{
    font-size: 20px;
  }
  .help-box p{
    font-size: 16px;
  }
}
@media screen  and (max-width:1000px) {
  .help-box{
    flex-basis: 50%;
  }
  .box2{
    border-right: none;
    border-bottom: 1px solid var(--line);
  }
  .box3{
    border-right: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
  }
  .box4{
    border-bottom: 1px solid var(--line);
  }
  .box5{
    border-left: none;
  }
}

@media screen  and (max-width:800px) {
  .home-help h2{
    font-size: 35px;
  }
}
@media screen  and (max-width:600px) {
  .home-help h2{
    font-size: 30px;
    text-align: left;
  }
  .help-box{
    flex-basis: 100%;
  }
  .box1{
    border-right: none;
  }
  .box3{
    border-right: none;
  }
  .box4{
    border-bottom: 1px solid var(--line);
  }
  .box5{
    border-right: none;
    border-bottom: 1px solid var(--line);
  }
}


/* home ends here  */


/* About starts here  */

/* about top starts here  */

.about-icon{
  display: flex;
  padding: 20px;
  justify-content: center;
  color: var(--secondary-text);
  opacity: 0.75;
  font-size: 12px;
  align-items: center;
}

.about-icon a {
  text-decoration: none;
  color: var(--secondary-text);
}
.about-icon i{
  margin-right: 10px;
}


.about-top-text{
  color: var(--secondary-text);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  margin: 10px auto;
}

.about-top-text h2{
  font-size: 60px;
  font-weight: 500;
}
.about-top-text p{
  font-size: 18px;
  font-weight: 300;
  opacity: 0.75;
  margin: 20px 0;
  line-height: 25px;
}

.about-top-last{
  display: flex;
  justify-content: center;
}
.about-top-elem{
  display: flex;
  color: var(--secondary-text);
  align-items: center;
  width: 250px;
  justify-content: center;
  border-right: 0.75px solid rgba(228, 228, 231, 0.3);
  margin: 50px 0;
  transition: 0.4s all ease-in-out;
}
.about-top-elem:hover{
  transform: scale(1.1);
}
.about-top-elem img{
  margin-right: 15px;
}
.elem3{
  border-right: none;
}

@media screen and (max-width: 1250px){
  .about-top-text{
    width: 70%;
  }
}
@media screen and (max-width: 1140px){
  .about-top-text{
    width: 80%;
  }
}
@media screen and (max-width: 820px){
  .about-top-text{
    width: 85%;
  }
}
@media screen and (max-width: 620px){
  .about-top-text{
    width: 95%;
  }
  .about-top-elem img{
    margin-right: 10px;
    height: 25px;
  }
  .about-top-elem{
    width: 180px;
    font-size: 14px;
  }
  .about-top-text h2{
    font-size: 50px;
  }
}
@media screen and (max-width: 420px){
  .about-top-text p{
    font-size: 16px;
  }
  .about-top-elem img{
    margin-right: 8px;
    height: 20px;
  }
  .about-top-elem{
    width: 150px;
    font-size: 12px;
  }
}

/* about top ends here  */

/* about company starts here  */

.about-company{
  display: flex;
  justify-content: center;
  margin: 20px 12%;
}
.about-container{
  flex-basis: 30%;
  background: var(--about-container);
  padding: 30px 20px;
  margin: 5px;
  color: var(--secondary-text);
  border-radius: 6px;
  transition: 0.4s all ease-in-out;
}
.about-container:hover{
  background: var(--about-secondary-container);
  transform: scale(1.05);
}
.about-container-mid{
  background-color: var(--about-secondary-container);
}
.about-container-mid:hover{
  background-color: var(--about-container);
}
.about-container h2{
  font-size: 40px;
  font-weight: 400;
  margin: 20px 0;
}
.about-container p{
  font-size: 15px;
  font-weight: 300;
}

.container-num{
  display: flex;
  justify-content: space-between;
}
.container-num p{
  color: #B1B2B4;
  opacity: 60%;
  font-size: 40px;
}

@media screen and (max-width: 1300px){
  .about-company{
    margin: 20px 10%;
  }
}
@media screen and (max-width: 1120px){
  .about-company{
    margin: 20px 8%;
  }
  .about-container h2{
    font-size: 35px;
  }
}
@media screen and (max-width: 1000px){
  .about-company{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .about-container{
    flex-basis: 48%;
  }
}
@media screen and (max-width: 700px){

  .about-container{
    flex-basis: 100%;
  }
}
@media screen and (max-width: 450px){

  .about-company{
    margin: 20px 4%;
  }
}

/* about company ends here  */


/* about-work starts here  */
.about-work{
  margin: 100px 9.5%;
}

.about-work-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 60px;
  transition: all 0.4s ease-in-out;
}
.about-work-container:hover{
  transform: scale(1.03);
}

.about-work-img img{
  height: 420px;
}
.about-work-text{
  flex-basis: 40%;
  color: var(--secondary-text);
}

.about-work-text h2{
  font-size: 35px;
  margin: 20px 0;
}
.about-work-text p{
  font-size: 16px;
  opacity: 0.75;
}

@media screen and (max-width: 1100px){
  .about-work-img img{
    height: 380px;
  }
  .about-work-text h2{
    font-size: 30px;
  }
  .about-work-text p{
    font-size: 15px;
  }
  
}
@media screen and (max-width: 1000px){
  .about-work-container{
    flex-direction: column;  
    align-items: flex-start;
  }
  .work-container2{
    flex-direction: column-reverse;
  }
  .about-work-text h2{
    margin-top: 40px;
  }
}
@media screen and (max-width: 500px){
  .about-work-text h2{
    font-size: 25px;
  }
  .about-work-img img{
    height: 320px;
  }
  .about-work-text p{
    font-size: 13px;
  }
}
@media screen and (max-width: 420px){
  .about-work-text h2{
    font-size: 19px;
  }
  .about-work-img img{
    height: 280px;
  }
  .about-work-text p{
    font-size: 12px;
  }
}

/* about work ends here  */

/* about services starts here  */

.about-services{
  margin: 50px 9.5%;
  background-color: var(--box-background);
  border: 10px solid var(--box-border);
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services-text{
  flex-basis: 50%;
  color: var(--secondary-text);
  font-size: 35px;
  display: flex;
  justify-content: center;
}
.services-text p{
  width: 70%;
  font-weight: 500;
  margin-right: 20px;
}
.services-container{
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services-box{
  display: flex;
  background: rgba(67,80,128,0.2);
  border: 1px solid rgba(73,81,120,0.5);
  border-radius: 12px;
  align-items: center;
  /* height: 200px; */
  margin-bottom: 20px;
  width: 75%;
  padding: 20px;
  transition: all 0.4s ease-in-out;
}
.services-box:hover{
  background: rgba(67,80,128,0.9);
  border: 1px solid rgba(73,81,120,0.9);

}

.services-box-icon img{
  height: 75px;
  margin-right: 20px;
}
.services-box-text{
  color: var(--secondary-text);
}
.services-box-text h2{
  font-size: 18px;
  margin-bottom: 10px;
}
.services-box-text p{
  font-size: 16px;
}

@media screen and (max-width: 1390px){
  .services-text p{
    width: 85%;
  }
  .services-box{
    width: 80%;
  }
}
@media screen and (max-width: 1280px){
  .services-box{
    width: 90%;
  }
}
@media screen and (max-width: 1180px){
  .services-text p{
    width: 90%;
    font-size: 30px;
  }
  .services-box{
    width: 95%;
  }
}
@media screen and (max-width: 975px){
  .about-services{
    flex-direction: column;
  }
  .services-text{
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .services-text p{
    width: 80%;
    font-size: 30px;
  }
}
@media screen and (max-width: 630px){

  .services-text p{
    width: 90%;
    font-size: 30px;
  }
  .services-box{
    width: 100%;
  }
  /* .services-box-text h2{
    font-size: 16px;
  }
  .services-box-text p{
    font-size: 14px;
  } */
  .services-box-icon img{
    height: 55px;
    margin-right: 10px;
  }
  .about-services{
    margin: 50px 4%;
    padding: 30px;
  }
}
@media screen and (max-width: 475px){

  .services-text p{
    width: 95%;
    font-size: 25px;
  }
  .services-box-text h2{
    font-size: 16px;
  }
  .services-box-text p{
    font-size: 14px;
  }
  .services-box-icon img{
    height: 50px;
    margin-right: 8px;
  }
  .about-services{
    margin: 50px 1%;
    padding: 10px;
  }
  .services-box{
    padding: 20px 10px;
  }
  
}

/* about services ends here  */


/* about network starts here  */

.about-network{
  margin: 40px 14%;
}
.about-network-heading{
  display: flex;
  justify-content: space-between;
  color: var(--secondary-text);
  margin-bottom: 30px;
}

.about-network-heading h2{
  flex-basis: 48%;
  font-size: 40px;
  font-weight: 400;
}
.about-network-heading p{
  flex-basis: 48%;
  font-size: 16px;
  line-height: 25px;
  opacity: 0.7;
}
.about-network-heading span{
  background-color: var(--primary-text-back);
  padding: 0 5px;
}

.about-network-img img{
  width: 100%;
  /* overflow: hidden; */
}

.about-network-list{
  margin-top: 45px;
  padding: 0 4%;
  /* margin: 45px 5%; */
  border-bottom: 1px solid var(--line);
}
.network-list-title{
  display: flex;
  color: var(--secondary-text);
  justify-content: space-between;
  margin: 25px 0 ;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
}
.network-list-title-h3{
  display: flex;
}
.network-list-title-h3 h3{
  font-size: 24px;
  font-weight: 300;
  transition: 0.4s all ease-in-out;
}
.network-list-title-h3 span{
  font-size: 20px;
  font-weight: 300;
  margin-right: 25px;
  opacity: 0.7;
  transition: 0.4s all ease-in-out;
}
.network-list-title i{
  font-size: 12px;
  background-color: var(--secondary-text);
  color: var(--primary-background);
  padding: 12.5px 13.5px 10px 13.5px;
  border-radius: 50%;
  transition: 0.4s all ease-in-out;
}
.network-list-content{
  display: flex;
  align-items: flex-start;
  width: 90%;
  justify-content: center;
}
.network-list-content img{
  width: 200px;
  margin-right: 25px;

}

.network-list-content-mid{
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
}
.network-list-content p{
  color: var(--secondary-text);
  font-size: 18px;
  line-height: 40px;
  opacity: 0.75;
  margin-bottom: 40px;
}

.network-list-title:hover h3{
  font-weight: bold;
}
.network-list-title:hover h3 span{
  font-weight: bold;
}
.network-list-title:hover i{
  background-color: var(--main);
  color: var(--secondary-text);
}

@media screen and (max-width: 1200px){
  .about-network-list{
    margin: 45px 0;
  }
}
@media screen and (max-width: 980px){
  .about-network-heading{
    flex-direction: column;
  }
  .about-network-heading h2{
    margin-bottom: 20px;
  }
  .about-network{
    margin: 40px 10%;
  }
  .network-list-content{
    flex-direction: column;
  }
  .network-list-content img{
    margin-bottom:  20px;
  }
}
@media screen and (max-width: 800px){
  .network-list-title-h3 h3{
    font-size: 20px;
  }
  .network-list-title-h3 span{
    font-size: 16px;
  }
  .network-list-content p{
    font-size: 17px;
  }
}

@media screen and (max-width: 630px){
  .about-network-heading h2{
    font-size: 30px;
  }
  .about-network-img{
    display: none;
  }
  .about-network{
    margin: 40px 5%;
  }
  .network-list-title-h3 h3{
    font-size: 18px;
  }
  .network-list-title-h3 span{
    font-size: 15px;
  }
  .network-list-content p{
    font-size: 16px;
  }
}
@media screen and (max-width: 520px){
  .network-list-title-h3 h3{
    font-size: 16px;
    margin-right: 20px;
  }
  .network-list-title-h3 span{
    font-size: 13px;
  }
  .network-list-content p{
    font-size: 14px;
  }
}
@media screen and (max-width: 490px){
  .about-network-heading h2{
    font-size: 30px;
  }
  .about-network-heading p{
    font-size: 12px;
  }
  .about-network{
    margin: 40px 4%;
  }
}

/* about network ends here  */



/* about quality starts here  */
.about-quality{
  margin: 80px 14%;
}
.about-quality h2{
  color: var(--secondary-text);
  font-size: 30px;
  padding: 40px;
}

.quality-section{
  display: flex;
  margin-top: 50px;
}
.quality-img{
  width: 35%;
}
.quality-img img{
  height: 78%;
  width: auto;
}
.quality-content{
  border-top: 1px solid var(--line);
  /* border-bottom: 1px solid var(--line); */
  margin-bottom: 0;
}
.quality-content-list{
  display: flex;
  color: var(--secondary-text);
  border-top: 1px solid var(--line);
  border-bottom: 1px solid var(--line);
  padding: 20px 0;
  margin: 0;
}

.quality-content-list h3{
  flex-basis: 40%;
  font-size: 20px;
  margin-right: 20px;
}
.quality-content-list p{
  flex-basis: 60%;
  font-size: 15px;
  line-height: 23px;
  opacity: 0.9;
}

@media screen and (max-width: 1460px){
  .quality-img{
    width: 40%;
  }
}
@media screen and (max-width: 1320px){
  .about-quality{
    margin: 80px 12%;
  }

}
@media screen and (max-width: 1180px){
  .quality-img img{
    height: 70%;
  }
}
@media screen and (max-width: 1060px){
  .about-quality{
    margin: 80px 8%;
  }
}
@media screen and (max-width: 980px){
  .quality-section{
    flex-direction: column;
  }
  .quality-img{
    margin-bottom: 30px;
  }
  .quality-img img{
    width: 200%;
  }
  .about-quality h2{
    font-size: 25px;
  }
  .about-quality{
    margin: 50px 8%;
  }
  .quality-section{
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px){
  .about-quality{
    margin: 40px 4%;
  }
  .about-quality h2{
    font-size: 20px;
    padding: 40px 0;
  } 


}
@media screen and (max-width: 500px){
  .quality-content-list{
    flex-direction: column;
  }
  .quality-content-list p{
    margin-top: 20px;
  }
}

/* quality section ends here  */

/* About ends here  */

/* Message Starts here */

.message h1{
  font-size: 55px;
  text-align: center;
  color: var(--secondary-text);
  margin-top: 25px;
}

.message-container{
  display: flex;
  justify-content: center;
  width: 48%;
  margin: 100px auto;
  color: var(--secondary-text);
}

.message-container-text {
  margin-left: 50px;
  transition: all 0.4s ease-in-out;
}
.message-container-text:hover {
  transform: scale(1.05);
}
.message-container-text p{
  padding-left: 20px;
  border-left: 5px solid var(--main);
  opacity: 0.75;
}
.message-container-img img{
  height: 380px;
}

@media screen and (max-width: 1450px){
  .message-container{
    width: 50%;
  }
}
@media screen and (max-width: 1350px){
  .message-container{
    width: 55%;
  }
}
@media screen and (max-width: 1200px){
  .message-container{
    width: 60%;
  }
  .message h1{
    font-size: 50px;
  }
}
@media screen and (max-width: 1100px){
  .message-container{
    width: 70%;
  }
}
@media screen and (max-width: 950px){
  .message-container{
    width: 80%;
  }
}
@media screen and (max-width: 800px){
  .message-container{
    width: 90%;
  }
  .message-container-text {
    margin-left: 30px;
  }
  .message h1{
    font-size: 45px;
  }
}
@media screen and (max-width: 680px){
  .message-container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }
  .message-container-img{
    margin-bottom: 30px;
  }
  .message-container-text {
    margin-left: 0px;
  }
  .message-container-img{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* .message-container-img img{
    content: url("../public/assets/images/chairman_mid.png");
  } */
  .message h1{
    font-size: 35px;
  }
}

@media screen and (max-width: 430px){
  .message h1{
    font-size: 28px;
  }
  .message-container{
    margin: 30px auto;
  }
  .message h1{
    margin-top: 5px;
  }
}

/* message ends here  */



/* contact starts here  */

.contact h2{
  font-size: 55px;
  text-align: center;
  color: var(--secondary-text);
  margin: 25px 0;
}

/* .contact-container{

  background: rgb(25,38,87);
background: linear-gradient(45deg, rgba(25,38,87,1) 41%, rgba(2,112,183,1) 100%);
} */

.contact-container{
  display: flex;
  justify-content: center;
  margin: 0 9.5%;
  margin-bottom: 100px;
  border-radius: 16px;
  overflow: hidden;
  /* align-items: center; */
}

.contact-info{
  background: rgb(25,38,87);
  background: linear-gradient(45deg, rgba(25,38,87,1) 44%, rgba(10,87,150,1) 100%);
  color: white;
  padding: 60px 50px;

  flex-basis: 40%;
}

.contact-info h3{
  font-size: 30px;
  margin-bottom: 40px;
}

.info-detail{
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.info-detail-icon img{
  background: rgba(0, 118, 232, 0.1)
  ;
  padding: 10px;
  border-radius: 50%;
}
.info-detail-text{
  margin-left: 20px;
}
.info-detail-text h4{
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 8px;
  opacity: 0.75;
}
.info-detail-text a{
  text-decoration: none;
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 300;
}
.info-detail-text p{
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 300;
  width: 80%;
}

.contact-info-social{
  margin-top: 70px;
}

.info-social-text{
  display: flex;
}
.info-social-text img{
  margin-right: 20px;
}
.info-social-text p{
  font-size: 14px;
}

.info-social-icons{
  margin-top: 15px;
}
.info-social-icons a{
  margin-right: 7px;
}

.contact-form{
  background-color: var(--secondary-text);
  flex-basis: 60%;

  padding: 40px 60px;
}

.contact-form form label{
  color: var(--main);
}

.form-content{
  display: flex;
  flex-wrap: wrap;

}
.form-content label{
  display: inline-block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form-content input{
  padding: 14px;
  border: 2px solid var(--line);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
.form-content textarea{
  padding: 14px;
  border: 2px solid var(--line);
  border-radius: 5px;
  width: 100%;
}

.form-name{
  flex-basis: 49%;
  margin-right: 1%;
}

.form-email{
  flex-basis: 49%;
  margin-left: 1%;
}

.form-subject{
  flex-basis: 100%;
}
.form-website{
  flex-basis: 100%;
}

.form-message{
  flex-basis: 100%;
}



.form-content button{
  background: var(--main);
  padding: 15px 40px;
  color: white;
  border: none;
  margin-top: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.form-content button:hover{
  background: var(--secondary-background);
}

@media screen and (max-width: 1440px) {
  .info-detail-text p{
    width: 100%;
  }
}
@media screen and (max-width: 1150px) {
  .contact-container{
    margin: 0 5%;
    margin-bottom: 75px;
  }
  .contact-form{
    padding: 40px 40px;
  }
  .contact h2{
    font-size: 45px;
  }
}
@media screen and (max-width: 1000px) {

  .contact-container{
    flex-direction: column;
    
  }
  .contact-info-social{
    margin-top: 40px;
  }
  /* .form-name{
    flex-basis: 100%;
  }
  .form-email{
    flex-basis: 100%;
  } */
  /* .form-content button{
    margin-top: 15px;
  } */
}
@media screen and (max-width: 700px) {
  .form-name{
    flex-basis: 100%;
  }
  .form-email{
    flex-basis: 100%;
  }
  .contact h2{
    font-size: 35px;
  }
}

@media screen and (max-width: 450px) {
  .contact-info{
    padding: 55px 40px;
  }
  .contact h2{
    font-size: 25px;
  }
}
@media screen and (max-width: 375px) {
  .contact-info{
    padding: 55px 20px;
  }
  .contact-form{
    padding: 30px 20px;
  }
  .contact-container{
    margin: 0 3%;
    margin-bottom: 65px;
  }
}

/* contact ends here  */


/* adding elements start here  */

/* element 1  */
.element1{
  position: absolute;
  z-index: -1;
  overflow: hidden;
  margin-top: -50px;
}

.element1 img{
  width: 100%;
}
.element2{
  position: absolute;
  margin-top: -1000px;
  z-index: -4;
}
.element2 img{
  width: 100%;
}

.element3{
  position: absolute;
  z-index: 3;
  margin-top: -340px;
  margin-left: -40px ;
}
@media screen and (max-width: 1350px) {
  .element2{
    margin-top: -850px;
  }
}
@media screen and (max-width: 1200px) {
  .element2{
    margin-top: -700px;
  }
  .element3{
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .element1{
    display: none;
  }
  .element2{
    display: none;
  }
}


.element4{
  position: absolute;
  z-index: -1;
  margin-top: -10px;
}
.element4 img{
  width: 100%;
}
.element5{
  position: absolute;
  z-index: -1;
  margin-top: -10px;
}
.element5 img{
  width: 100%;
}
.element6{
  position: absolute;
  z-index: -1;
  margin-top: 650px;
}
.element6 img{
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .element4{
    display: none;
  }
  .element5{
    display: none;
  }
  .element6{
    display: none;
  }
}

.element7 img{
  margin-left: 30%;
  margin-top: 18px;
  z-index: -1;
  position: absolute;
}

@media screen and (max-width: 1450px) {
  .element7 img{
    margin-left: 29%;
  }
}
@media screen and (max-width: 1380px) {
  .element7 img{
    margin-left: 27%;
  }
}
@media screen and (max-width: 1250px) {
  .element7 img{
    margin-left: 25%;
  }
}
@media screen and (max-width: 1080px) {
  .element7 img{
    margin-left: 23%;
  }
}
@media screen and (max-width: 1000px) {
  .element7 {
    display: none;
  }
}

.element8{
  position: absolute;
  margin-top: 140px;
  margin-left: 400px;
}
.element8 img{
  width: 80%;
}

.element9{
  position: absolute;
}
.element9 img{
  width: 100%;
}
@media screen and (max-width: 1350px) {
  .element8 {
    margin-top: 140px;
    margin-left: 350px;
  }
}
@media screen and (max-width: 1150px) {
  .element8 {
    margin-top: 140px;
    margin-left: 280px;
  }
}
@media screen and (max-width: 1000px) {
  .element8 {
    display: none;
  }
  .element9 {
    display: none;
  }
}



